export default {
  title: 'File upload - Recv',
  disclaimer: '',
  dateTimeFormat: 'MM/DD/YYYY hh:mm a',
  dateFormat: 'MM/DD/YYYY',
  timeFormat: 'hh:mm a',

  landing: {
    invitePlaceholder: 'Code',
    inviteButton: 'Check code',
    inviteButtonChecking: 'Checking...',

    loginButton: 'Log in'
  },

  notification: {
    invalidCode: 'The specified code is invalid or has expired',
    invalidLogin: 'The specified username or password is incorrect'
  },

  messageFrom: 'Message from {owner}',
  uploadDisclaimer: '',
  expirationNotice: {
    never: '',
    date: 'Files uploaded for this invite code will be automatically deleted on {date} at {time}',
    timespan: 'Files uploaded for this invite code will be automatically deleted after {expiration}'
  },

  expirationValues: {
    h: '0 hours | 1 hour | {count} hours',
    d: '0 days | 1 day | {count} days',
    w: '0 weeks | 1 week | {count} weeks',
    M: '0 months | 1 month | {count} months',
    y: '0 years | 1 year | {count} years'
  },

  admin: {
    loading: 'Loading…',
    empty: 'No data',
    logout: 'Logout',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    deleteApply: 'Delete selection',

    diskspace: '{available} disk space available of {total} total',

    login: {
      usernamePlaceholder: 'Username or e-mail address',
      passwordPlaceholder: 'Password',
      button: 'Login',
      buttonChecking: 'Checking...'
    },

    menu: {
      uploads: 'Received',
      codes: 'Codes',
      users: 'Users'
    },

    uploads: {
      created: 'Date',
      code: 'Code',
      owner: 'Owner',
      expiration: 'Expires:',
      userDeleted: 'deleted',

      assign: 'Change owner',
      assignApply: 'Apply'
    },

    codes: {
      add: 'Generate code',

      list: {
        code: 'Code',
        owner: 'Owner',
        actions: 'Actions',
        userDeleted: 'deleted'
      },

      detail: {
        code: 'Code',
        codeHint: 'Direct link to the upload page for this code:',
        owner: 'Owner',
        created: 'Date created',
        description: 'Description',
        descriptionHint: 'The description will be visible only in the admin panel',
        expiration: 'Expires after',
        message: 'Message',
        messageHint: 'The message will be displayed to the user on the upload page after the code is entered. Markdown is supported.',

        expirationUnits: {
          never: 'Never',
          h: 'Hours:',
          d: 'Days:',
          w: 'Weeks:',
          M: 'Months:',
          y: 'Years:'
        },

        expirationHint: 'The maximum allowed expiration is {valueUnits}'
      }
    },

    users: {
      add: 'Add user',

      list: {
        username: 'Username / name',
        inactive: '(inactive)',
        email: 'Email',
        actions: 'Actions'
      },

      detail: {
        username: 'Username',
        name: 'Name',
        email: 'Email',
        password: 'Password',
        passwordHint: 'Enter a new password to change the password. Leave blank to keep the current password.',
        auth: {
          title: 'Authorization',
          viewAllCodes: 'Manage codes created by other users',
          viewAllUploads: 'Manage uploads created by other users',
          manageUsers: 'Manage users'
        },
        active: 'Active'
      }
    }
  }
}