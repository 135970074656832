export default {
  title: 'Bestandsoverdracht - Recv',
  disclaimer: '',
  dateTimeFormat: 'DD-MM-YYYY HH:mm',
  dateFormat: 'DD-MM-YYYY',
  timeFormat: 'HH:mm',

  landing: {
    invitePlaceholder: 'Code',
    inviteButton: 'Controleer code',
    inviteButtonChecking: 'Controleren...',

    loginButton: 'Inloggen'
  },

  notification: {
    invalidCode: 'De ingevoerde code is ongeldig of verlopen',
    invalidLogin: 'De ingevoerde gebruikersnaam of wachtwoord is incorrect'
  },

  messageFrom: 'Bericht van {owner}',
  uploadDisclaimer: '',
  expirationNotice: {
    never: '',
    date: 'Bestanden die worden geupload voor deze code worden automatisch verwijderd op {date} om {time}',
    timespan: 'Bestanden die worden geupload voor deze code worden automatisch verwijderd na {expiration}'
  },

  expirationValues: {
    h: '0 uren | 1 uur | {count} uren',
    d: '0 dagen | 1 dag | {count} dagen',
    w: '0 weken | 1 week | {count} weken',
    M: '0 maanden | 1 maand | {count} maanden',
    y: '0 jaren | 1 jaar | {count} jaren'
  },


  admin: {
    loading: 'Bezig met laden…',
    empty: 'Geen gegevens',
    logout: 'Uitloggen',
    cancel: 'Annuleren',
    save: 'Opslaan',
    delete: 'Verwijderen',
    deleteApply: 'Selectie verwijderen',

    diskspace: '{available} schijfruimte beschikbaar van {total} totaal',

    login: {
      usernamePlaceholder: 'Gebruikersnaam of e-mail adres',
      passwordPlaceholder: 'Wachtwoord',
      button: 'Inloggen',
      buttonChecking: 'Controleren...'
    },

    menu: {
      uploads: 'Ontvangen',
      codes: 'Codes',
      users: 'Gebruikers'
    },

    uploads: {
      created: 'Datum',
      code: 'Code',
      owner: 'Eigenaar',
      expiration: 'Verloopt:',
      userDeleted: 'verwijderd',

      assign: 'Verander eigenaar',
      assignApply: 'Toepassen'
    },

    codes: {
      add: 'Genereer code',

      list: {
        code: 'Code',
        owner: 'Eigenaar',
        actions: 'Acties',
        userDeleted: 'verwijderd'
      },

      detail: {
        code: 'Code',
        codeHint: 'Directe link naar de upload pagina voor deze code:',
        owner: 'Eigenaar',
        created: 'Datum aangemaakt',
        description: 'Omschrijving',
        descriptionHint: 'De omschrijving is alleen zichtbaar in beheer',
        expiration: 'Verloopt na',
        message: 'Bericht',
        messageHint: 'Het bericht wordt getoond aan de gebruiker op de upload pagina na het invoeren van de code. Markdown kan worden gebruikt.',

        expirationUnits: {
          never: 'Nooit',
          h: 'Uren:',
          d: 'Dagen:',
          w: 'Weken:',
          M: 'Maanden:',
          y: 'Jaren:'
        },

        expirationHint: 'De maximaal toegestane verlooptermijn is {valueUnits}'
      }
    },

    users: {
      add: 'Gebruiker toevoegen',

      list: {
        username: 'Gebruiker / naam',
        inactive: '(inactief)',
        email: 'Email',
        actions: 'Acties'
      },

      detail: {
        username: 'Gebruikersnaam',
        name: 'Naam',
        email: 'Email',
        password: 'Wachtwoord',
        passwordHint: 'Vul een nieuw wachtwoord in om deze te wijzigen. Laat leeg om het huidige wachtwoord te gebruiken.',
        auth: {
          title: 'Machtigingen',
          viewAllCodes: 'Codes beheren die door andere gebruikers zijn aangemaakt',
          viewAllUploads: 'Uploads beheren die door andere gebruikers zijn aangemaakt',
          manageUsers: 'Gebruikers beheren'
        },
        active: 'Actief'
      }
    }
  }
}