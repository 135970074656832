<template>
  <div id="app" class="container">
    <div v-cloak>
      <div class="notificationContainer">
        <div class="notification" :class="{ error: notification != null && notification.error }" v-if="notification != null" @click.prevent="hideNotification">
          <span class="message">{{ notification.message }}</span>
        </div>
      </div>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import shared from './shared';


export default {
  name: 'app',
  data() {
    return {
      notification: null
    }
  },

  created()
  {
    var self = this;
    document.title = self.$i18n.t('title');

    self.notificationTimer = null;

    shared.$on('showNotification', (message, error) =>
    {
      var self = this;
      self.notification = {
        message: message,
        error: error
      };

      if (self.notificationTimer != null)
        clearTimeout(self.notificationTimer);

      self.notificationTimer = setTimeout(() =>
      {
        self.notification = null;
        self.notificationTimer = null;
      }, 5000);
    });

    shared.$on('hideNotification', () =>
    {
      var self = this;
      self.notification = null;

      if (self.notificationTimer != null)
      {
        clearTimeout(self.notificationTimer);
        self.notificationTimer = null;
      }
    });
  }
}
</script>

<style lang="scss">
@import '~purecss/build/base.css';
@import '~purecss/build/buttons.css';
@import '~purecss/build/forms.css';
@import '~purecss/build/grids.css';

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}


body
{
  background-color: #f0f0f0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12pt;
}

a, a:hover
{
  text-decoration: none;
}

a
{
  color: #228dd4;
}

.pure-form select
{
  padding-top: 0;
  padding-bottom: 0;
}

/*
  pure-g uses hardcoded sans-serif to get accurate alignment, this class
  is added as a workaround for text in rows
*/
.text
{
  font-family: 'Open Sans', sans-serif;
}

.container
{
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
}

.content
{
  background-color: white;
  margin: 2rem;
  padding: 2rem;
  box-shadow: 0 0 15px #c0c0c0;
}


.notificationContainer
{
  position: fixed;
  top: 1.5rem;
  z-index: 666;
  width: 512px;
  left: 50%;
}


.notification
{
  background: #d5e8f6;
  border: solid 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px #c0c0c0;
  color: black;
  cursor: pointer;
  padding: .5em;
  margin-bottom: 2rem;

  position: relative;
  left: -50%;
}

.notification .message
{
  white-space: pre;
}

.notification.error
{
  background: #973a38;
}


.logo
{
  margin-bottom: 2rem;
  text-align: center;
}

.disclaimer
{
  font-size: 8pt;
  text-align: center;
}

.pure-form-description
{
  font-size: 80%;
  color: #808080;

  margin-left: 180px;
  margin-bottom: .5rem;
}

.confirmDelete
{
  color: red !important;
}

.pure-button-confirm-delete
{
  background: rgb(223, 117, 20);
  color: white;
}
</style>