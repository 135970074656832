import Vue from 'vue';
import Cookies from 'js-cookie';
import axios from 'axios';


export default new Vue({
  data() {
    return {
      uploadToken: null,
      adminToken: null,
      user: null
    }
  },

  created()
  {
    var self = this;

    var cookie = Cookies.get('adminToken');
    if (typeof cookie !== 'undefined')
      self.adminToken = cookie;


    self.$on('apiError', (error, $router) =>
    {
      if (error.response && error.response.status == 403)
      {
        self.adminToken = null;
        $router.push('/admin');
      }
      else
        self.$emit('showNotification', error.message);
    });
  },

  watch: {
    adminToken(newValue)
    {
      var self = this;

      if (newValue !== null)
      {
        Cookies.set('adminToken', newValue);

        axios.get('/admin/whoami', {
          headers: {
            Authorization: 'Bearer ' + self.adminToken
          }})
          .then((response) =>
          {
            self.user = response.data;
          })
          .catch((error) =>
          {
          });
      }
      else
      {
        Cookies.remove('adminToken');
        self.user = null;
      }
    }
  }
});