// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!purecss/build/base.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js!purecss/build/buttons.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../node_modules/css-loader/dist/cjs.js!purecss/build/forms.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../node_modules/css-loader/dist/cjs.js!purecss/build/grids.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:\"Open Sans\";font-style:normal;font-weight:400;src:url(\"/fonts/open-sans-v15-latin-regular.eot\");src:local(\"Open Sans Regular\"),local(\"OpenSans-Regular\"),url(\"/fonts/open-sans-v15-latin-regular.eot?#iefix\") format(\"embedded-opentype\"),url(\"/fonts/open-sans-v15-latin-regular.woff2\") format(\"woff2\"),url(\"/fonts/open-sans-v15-latin-regular.woff\") format(\"woff\"),url(\"/fonts/open-sans-v15-latin-regular.ttf\") format(\"truetype\"),url(\"/fonts/open-sans-v15-latin-regular.svg#OpenSans\") format(\"svg\")}body{background-color:#f0f0f0;font-family:\"Open Sans\",sans-serif;font-size:12pt}a,a:hover{text-decoration:none}a{color:#228dd4}.pure-form select{padding-top:0;padding-bottom:0}.text{font-family:\"Open Sans\",sans-serif}.container{margin-left:auto;margin-right:auto;max-width:768px}.content{background-color:#fff;margin:2rem;padding:2rem;box-shadow:0 0 15px silver}.notificationContainer{position:fixed;top:1.5rem;z-index:666;width:512px;left:50%}.notification{background:#d5e8f6;border:solid 1px rgba(0,0,0,.25);box-shadow:0 0 10px silver;color:#000;cursor:pointer;padding:.5em;margin-bottom:2rem;position:relative;left:-50%}.notification .message{white-space:pre}.notification.error{background:#973a38}.logo{margin-bottom:2rem;text-align:center}.disclaimer{font-size:8pt;text-align:center}.pure-form-description{font-size:80%;color:gray;margin-left:180px;margin-bottom:.5rem}.confirmDelete{color:red !important}.pure-button-confirm-delete{background:#df7514;color:#fff}", ""]);
// Exports
module.exports = exports;
